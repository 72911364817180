import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import { useEditorHandlers } from './@editor';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from '../Link';
import { NavigationLayoutEnum, DrawerEnum } from '../../types/enum';
import Image, { ImageLoaderProps } from 'next/image';
import { Property } from 'csstype';

const NavigationMenu = dynamic<any>(() => import('./NavigationMenu').then((mod) => mod.NavigationMenu), {
  ssr: false,
});
const Drawer = dynamic<any>(() => import('../Drawer').then((mod) => mod.Drawer), {
  ssr: false,
});
const DrawerContent = dynamic<any>(() => import('../NavigationBarB/DrawerContent').then((mod) => mod.DrawerContent), {
  ssr: false,
});
const NavigationSearchResults = dynamic<any>(
  () => import('../NavigationBarB/NavigationSearchResults').then((mod) => mod.NavigationSearchResults),
  {
    ssr: false,
  }
);
const LanguageSelectMenu = dynamic<any>(() => import('../LanguageSelectMenu').then((mod) => mod.LanguageSelectMenu), {
  ssr: false,
});
const LastPlayedMenu = dynamic<any>(() => import('../LastPlayedMenu').then((mod) => mod.LastPlayedMenu), {
  ssr: false,
});
const SearchField = dynamic<any>(() => import('../SearchField').then((mod) => mod.SearchField), {
  ssr: false,
});

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: theme.theme_component_navigation_bar_a_background_color || theme.palette.primary.main,
  backgroundPosition: theme.theme_component_navigation_bar_a_background_position || 'center',
  backgroundSize: theme.theme_component_navigation_bar_a_background_size || 'cover',
  backgroundRepeat: theme.theme_component_navigation_bar_a_background_repeat || 'no-repeat',
  borderBottomWidth: theme.theme_component_navigation_bar_a_border_bottom_width
    ? `${theme.theme_component_navigation_bar_a_border_bottom_width}px`
    : 0,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.theme_component_navigation_bar_a_border_bottom_color || theme.palette.divider,
  color: theme.theme_component_navigation_bar_a_color || theme.palette.primary.contrastText,
  outlineOffset: '-2px', // Help out cursor context editor.
  position: theme.theme_component_navigation_position as Property.Position || 'sticky'
})) as typeof AppBar;

const ToolbarLeft = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  height: '56px',
}));

const ToolbarCenter = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'stretch',
  padding: theme.spacing(0, 2),
}));

const ToolbarRight = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'center',
}));

const ButtonLogo = styled(Button)(({ theme }) => ({
  width: theme.theme_component_navigation_bar_logo_width || '150px',
})) as typeof Button;

const DrawerIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
})) as typeof IconButton;

const DrawerIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.theme_component_navigation_bar_a_icon_color || theme.palette.primary.contrastText,
}));

const NavigationMenuContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
  margin: 'auto',
  height: '100%',
}));

const SearchFieldStyled = styled(SearchField)(({ theme }) => ({
  flex: 1,
  margin: theme.spacing('auto', 0, 'auto ', 1),
}));

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.theme_component_navigation_bar_a_icon_color || theme.palette.primary.contrastText,
}));

export const NavigationBarA = () => {
  const theme = useTheme();
  const router = useRouter();
  const appContext = useAppContext();
  const { url, componentOptions, assetUrl, headerLogoId } = appContext.useConfig();

  const locales = router?.locales || [];
  const layout = componentOptions.component_navigation_bar_a_layout || NavigationLayoutEnum.DEFAULT;
  const lastPlayedButtonDisabled = componentOptions.component_navigation_bar_a_last_played_button_disabled;
  const languageMenuButtonDisabled =
    locales.length > 1 ? componentOptions.component_navigation_bar_language_menu_button_disabled : true;
  const drawerSearchAnchor: DrawerEnum =
    componentOptions.component_navigation_bar_a_search_drawer_anchor || DrawerEnum.RIGHT;
  const drawerAnchor: DrawerEnum = componentOptions.component_navigation_bar_a_drawer_anchor || DrawerEnum.LEFT;

  const [drawer, setDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [drawerSearch, setDrawerSearch] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [menuSliceAmount, setMenuSliceAmount] = React.useState(10);
  const [disableLiveSearch, setDisableLiveSearch] = React.useState(true);

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const belowLg = useMediaQuery(theme.breakpoints.down('lg'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const toolBarLeftRef = React.useRef<HTMLDivElement>(null);
  const toolBarCenterRef = React.useRef<HTMLDivElement>(null);
  const toolBarRightRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (anchorType: DrawerEnum, isOpen: boolean) => (event: KeyboardEvent) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setDrawer({ ...drawer, [anchorType]: isOpen });
  };

  const handleSearchToggle = (anchorType: DrawerEnum, isOpen: boolean) => (event: KeyboardEvent) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setDrawerSearch({ ...drawerSearch, [anchorType]: isOpen });
  };

  const handleClose = () => {
    setDrawer({ ...drawer, [drawerAnchor]: false });
    setDrawerSearch({ ...drawerSearch, [drawerSearchAnchor]: false });
  };

  React.useEffect(() => {
    const observer: ResizeObserver = new ResizeObserver(() => {
      const leftWidth = toolBarLeftRef.current?.offsetWidth || 0;
      const centerWidth = toolBarCenterRef.current?.offsetWidth || 0;
      const rightWidth = toolBarRightRef.current?.offsetWidth || 0;
      const usedWidth = leftWidth + centerWidth + rightWidth + parseInt(theme.spacing(4).replace('px ', ''));

      if (usedWidth > window?.innerWidth) {
        setMenuSliceAmount((previousState) => previousState - 1);
      }
    });

    if (observer) {
      if (toolBarLeftRef.current) {
        observer.observe(toolBarLeftRef.current);
      }
      if (toolBarCenterRef.current) {
        observer.observe(toolBarCenterRef.current);
      }
      if (toolBarRightRef.current) {
        observer.observe(toolBarRightRef.current);
      }
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [toolBarLeftRef, toolBarCenterRef, toolBarRightRef]);

  React.useEffect(() => {
    // Always close our drawers.
    handleClose();

    if (router.pathname === '/search') {
      // Allow our search input field to run search queries.
      setDisableLiveSearch(false);
    } else {
      setDisableLiveSearch(true);
    }
  }, [router.asPath]);

  return (
    <>
      {belowLg && (
        <>
          <Drawer state={drawer} anchor={drawerAnchor} onToggle={handleToggle}>
            <DrawerContent>
              <NavigationMenu />
            </DrawerContent>
          </Drawer>
          <Drawer state={drawerSearch} anchor={drawerSearchAnchor} onToggle={handleSearchToggle}>
            <NavigationSearchResults />
          </Drawer>
        </>
      )}
      <AppBarStyled {...useEditorHandlers('Navigation Bar A')}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <ToolbarLeft ref={toolBarLeftRef}>
              <DrawerIconButton
                edge="start"
                size={sm ? 'medium' : 'small'}
                onClick={() => setDrawer({ ...drawer, [drawerAnchor]: true })}
                aria-label="menu"
              >
                <DrawerIcon />
              </DrawerIconButton>
              <ButtonLogo component={Link} href="/" title={url} aria-label="Home">
                <Image
                  loader={({ src }: ImageLoaderProps) => src}
                  src={`${assetUrl}${headerLogoId}/`}
                  alt={url}
                  layout="fill"
                  objectFit="contain"
                />
              </ButtonLogo>
            </ToolbarLeft>
            <ToolbarCenter ref={toolBarCenterRef}>
              {layout === NavigationLayoutEnum.DEFAULT && lg && (
                <NavigationMenuContainer>
                  <NavigationMenu sliceAmount={menuSliceAmount} />
                </NavigationMenuContainer>
              )}
            </ToolbarCenter>
            <ToolbarRight ref={toolBarRightRef}>
              {!lastPlayedButtonDisabled && (
                <LastPlayedMenu
                  size={sm ? 'medium' : 'small'}
                  edge="start"
                  variant={sm ? 'standard' : 'dot'}
                  overlap={sm ? 'rectangular' : 'circular'}
                />
              )}
              {!languageMenuButtonDisabled && (
                <LanguageSelectMenu
                  edge={lastPlayedButtonDisabled ? 'start' : undefined}
                  size={sm ? 'medium' : 'small'}
                />
              )}
              {md ? (
                <SearchFieldStyled disableLiveSearch={disableLiveSearch} />
              ) : (
                <IconButton
                  edge="end"
                  size={sm ? 'medium' : 'small'}
                  onClick={() => setDrawerSearch({ ...drawerSearch, [drawerSearchAnchor]: true })}
                  aria-label="search"
                >
                  <SearchIconStyled />
                </IconButton>
              )}
            </ToolbarRight>
          </Toolbar>
        </Container>
        {/*{layout === NavigationLayoutEnum.ONE && lg && (
          <Container maxWidth={false} className={classes.lowerBarContainer}>
            <Toolbar disableGutters variant="dense">
              <div className={classes.menuContainer}>
                <NavigationMenu sliceAmount={menuSliceAmount} variant="dense" />
              </div>
            </Toolbar>
          </Container>
        )}*/}
      </AppBarStyled>
    </>
  );
};
