import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_navigation_bar_a_background_color',
        label: 'Background color',
        type: 'color',
        default: theme.palette.primary.main,
      },
      {
        name: 'theme_component_navigation_bar_a_background_image',
        label: 'Background image',
        type: 'text',
        startAdornment: 'url(https://',
        endAdornment: '/)',
      },
      {
        name: 'theme_component_navigation_bar_a_background_image_gradient',
        label: 'Background image gradient',
        type: 'text',
        helperText: 'Note; any background image value gains priority over background gradient.',
      },
      {
        name: 'theme_component_navigation_bar_a_background_position',
        label: 'Background position',
        type: 'select',
        default: 'center',
        options: ['top', 'right', 'bottom', 'left', 'center'],
      },
      {
        name: 'theme_component_navigation_bar_a_background_size',
        label: 'Background size',
        type: 'select',
        default: 'cover',
        options: ['cover', 'contain', 'initial', 'inherit', 'auto'],
      },
      {
        name: 'theme_component_navigation_bar_a_background_repeat',
        label: 'Background repeat',
        type: 'select',
        default: 'no-repeat',
        options: ['no-repeat', 'repeat-y', 'repeat-x', 'repeat'],
      },
      {
        name: 'theme_component_navigation_bar_a_color',
        label: 'Text color',
        type: 'color',
        default: theme.palette.primary.contrastText,
      },
      {
        name: 'theme_component_navigation_bar_a_icon_color',
        label: 'Icon color',
        type: 'color',
        default: theme.palette.primary.contrastText,
      },
      {
        name: 'theme_component_navigation_bar_a_border_bottom_width',
        label: 'Border bottom width',
        type: 'number',
        default: 0,
        endAdornment: 'px',
      },
      {
        name: 'theme_component_navigation_bar_a_border_bottom_color',
        label: 'Border bottom color',
        type: 'color',
        default: theme.palette.divider,
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        label: 'Menu items',
      },
      {
        name: 'theme_component_navigation_menu_a_item_color',
        label: 'Text color',
        type: 'color',
        default: theme.palette.primary.contrastText,
      },
      {
        name: 'theme_component_navigation_menu_a_item_hover_color',
        label: 'Hover text color',
        type: 'color',
        default: theme.palette.action.hover,
      },
      {
        name: 'theme_component_navigation_menu_a_item_hover_background_color',
        label: 'Hover background color',
        type: 'color',
        default: theme.palette.action.hover,
      },
      {
        name: 'theme_component_navigation_menu_a_item_active_color',
        label: 'Active text color',
        type: 'color',
        default: theme.palette.action.active,
      },
      {
        name: 'theme_component_navigation_menu_a_item_active_background_color',
        label: 'Active background color',
        type: 'color',
        default: theme.palette.action.active,
      },
    ],
    configuration: [
      {
        name: 'component_navigation_bar_a_layout',
        label: 'Change the layout',
        type: 'select',
        default: 'default',
        options: ['default', 'navigation-below'],
      },
      {
        name: 'component_navigation_bar_a_drawer_anchor',
        label: 'Drawer navigation entry direction',
        type: 'select',
        default: 'left',
        options: ['top', 'right', 'bottom', 'left'],
      },
      {
        name: 'component_navigation_bar_a_search_drawer_anchor',
        label: 'Search drawer entry direction',
        type: 'select',
        default: 'right',
        options: ['top', 'right', 'bottom', 'left'],
      },
      {
        name: 'component_navigation_bar_a_last_played_button_disabled',
        label: 'Disable last played button',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_navigation_bar_a_language_menu_button_disabled',
        label: 'Disable language menu button',
        type: 'checkbox',
        default: false,
      },
    ],
  });
}